const ROUTER_TYPES = {
  ADD_ADVICE: "add-advice",
  DELETE_ADVICE: "delete-advice",
  // AI_HOME_PAGE: "ai-home-page",
  // INTELLIGENT_DIAGNOSIS: "ai-landing-page",
  WEEKLY_DATA: "weekly-data",
  // INDEX_TASK: "index-task",
  // CUSTOM_REPORTS: "custom-reports",
  // PEER_ANALYSIS: "peer-analysis",
  // SELECT_PRODUCT_ANALYSIS: "selection-analysis",
  // CONVERSION_ANALYSIS: "conversion-analysis",
  // ADVERTISING: "advertising",
  // REPORT_CONCLUSION: "report-conclusion",
  REPORT: "report",
  // OPERATING_GUIDE: "operating-guide",
  INDUSTRY_HOT_PRODUCTS: "industry-hot-products",
  HOT_PRODUCTS_REPORT: "hot-products-report",
  VIDEO_COURSE: "video-course",
  INDUSTRY_HOT_INQUIRIES: "industry-hot-inquiries",
};

const WEEKLY_DATA = {
  name: "店铺周数据记录",
  path: `/${ROUTER_TYPES.WEEKLY_DATA}`,
};

const REPORT = {
  name: "我的店铺360复盘",
  path: `/${ROUTER_TYPES.REPORT}`,
};
const INDUSTRY_HOT_INQUIRIES = {
  name: "指定同行询盘数查询",
  path: `/${ROUTER_TYPES.INDUSTRY_HOT_INQUIRIES}`,
  isNew: true,
  till: "2024-12-01",
  // isDot: true,
};
const INDUSTRY_HOT_PRODUCTS = {
  name: "询盘top20店铺明细表",
  path: `/${ROUTER_TYPES.INDUSTRY_HOT_PRODUCTS}`,
};

// const HOT_PRODUCTS_REPORT = {
//   name: "询盘top20产品明细表",
//   path: `/${ROUTER_TYPES.HOT_PRODUCTS_REPORT}`,
// };

const types = {
  REPORT,
  INDUSTRY_HOT_INQUIRIES,
  INDUSTRY_HOT_PRODUCTS,
  // HOT_PRODUCTS_REPORT,
  WEEKLY_DATA,
};

export { ROUTER_TYPES };
export default types;
